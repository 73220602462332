import React from 'react';

import Box from './styled/base/Box';
import Flex from './styled/base/Flex';
import Button from './styled/Button';
import Typography from '../components/styled/base/Typography';
import thanksDraw from '../images/thanks-draw.svg';

const DonationResult = ({ donation, setDonation }) => {
  const donationStatus = {
    open: 'open',
    paid: 'betaald',
    failed: 'mislukt',
    canceled: 'geannuleerd',
    expired: 'verlopen',
  };

  const donationDetails = {
    donation:
      donation.resource === 'subscription'
        ? `Iedere ${donation.interval}`
        : 'Eenmalige Donatie',
    name: donation.metadata.name,
    email: donation.metadata.email,
    message: donation.description,
    amount:
      (donation.amount.currency === 'EUR' ? '€' : donation.amount.currency) +
      ' ' +
      donation.amount.value,
  };

  return (
    <div>
      {donation.status === 'paid' || donation.status === 'active' ? (
        <Box boxShadow={0} py={3} px={[3, 5]} my={4}>
          <Typography textAlign="center" as="h1" color="primary" mb={5}>
            Bedankt voor uw donatie!
          </Typography>
          <Flex flexWrap="wrap" justifyContent="center" alignItems="flex-start">
            <Box
              width={[1, 1, 1, 'initial']}
              bg="primaryLight"
              p={3}
              mb={4}
              mr={3}
            >
              {Object.keys(donationDetails).map(key => (
                <Box p={1} key={key}>
                  <Typography
                    as="span"
                    fontWeight="bold"
                    display="inline-block"
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)} {' :'}
                  </Typography>
                  <Typography as="span" p={2} width="300px">
                    {donationDetails[key]}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box pl={[0, 0, 0, 4]} width={[1, 1, 1, 3 / 5]}>
              <img width="100%" src={thanksDraw} alt="thanks" />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Flex flexDirection="column">
          <Typography as="h3">
            Donatie mislukt! Reden: "{donationStatus[donation.status]}"
          </Typography>
          <Button
            color="background"
            mb="4"
            width="100px"
            height="40px"
            fontWeight="semibold"
            fontSize="3"
            onClick={() => setDonation({})}
          >
            Probeer opnieuw
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default DonationResult;
