import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import theme from '../../util/theme';
const { colors } = theme;


const sizeMap = {
  'small': 16,
  'medium': 24,
  'large': 32,
  'huge': 48,
};


const rotationAnimation = keyframes`
    100% { transform:rotate(360deg); };
`;
const StyledSvg = styled.svg`
  animation: ${rotationAnimation} 600ms linear infinite;
`;

const Spinner = ({ color, size }) => {
  return (
    <StyledSvg
      dangerouslySetInnerHTML={{
        __html: `
                    <defs>
                        <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='spinner-gradient-${color}'>
                            <stop stop-color='${colors[color]}' offset='0%'></stop>
                            <stop stop-color='${colors[color]}' offset='28.38%'></stop>
                            <stop stop-color='#FFFFFF' stop-opacity='0' offset='100%'></stop>
                        </linearGradient>
                    </defs>
                    <path d='M12 24c6.63 0 12-5.37 12-12S18.63 0 12 0 0 5.37 0 12c0 4.4 2.4 8.4 6.2 10.5.8.45 1.8.16 2.25-.64.44-.8.15-1.8-.65-2.25-2.75-1.5-4.5-4.4-4.5-7.6 0-4.8 3.9-8.7 8.7-8.7 4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.7-8.7 8.7-.9 0-1.66.74-1.66 1.65 0 .92.75 1.66 1.66 1.66z' fill='url(#spinner-gradient-${color})' stroke='none' fill-rule='evenodd'/>
                `,
      }}
      height={sizeMap[size]}
      viewBox="0 0 24 24"
      width={sizeMap[size]}
    />
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
};

Spinner.defaultProps = { color: 'secondary', size: 'large' };

export default Spinner;
