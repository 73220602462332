import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Typography from '../../components/styled/base/Typography';

import Box from '../../components/styled/base/Box';
import DonationResult from '../../components/DonationResult';
import DonationForm from '../../components/DonationForm';
import Spinner from '../../components/styled/Spinner';
import ErrorReport from '../../components/styled/ErrorReport';

const SupportUs = ({ location }) => {
  const [donation, setDonation] = useState({});
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state && location.state.donation) {
      setDonation(location.state.donation);
      setServerError(false);
    }
  }, [location.state]);

  return (
    <Layout>
      <SEO title="Support Us" />
      <Typography color="primary" as="h1">
        Steun ons
      </Typography>
      {loading ? (
        <Box py="30px" textAlign="center">
          <Spinner size="huge" />
        </Box>
      ) : serverError ? (
        <ErrorReport
          serverError={serverError}
          setServerError={setServerError}
        />
      ) : donation.id ? (
        <DonationResult donation={donation} setDonation={setDonation} />
      ) : (
        <DonationForm
          setDonation={setDonation}
          setLoading={setLoading}
          setServerError={setServerError}
        />
      )}
    </Layout>
  );
};

export default SupportUs;
