import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styled from 'styled-components';

import Button from '../components/styled/Button';
import FormControl from '../components/styled/FormControl';
import FormGroup from '../components/styled/FormGroup';
import Flex from '../components/styled/base/Flex';
import Box from '../components/styled/base/Box';
import Typography from '../components/styled/base/Typography';
import donationDraw from '../images/donation-draw.svg';

import * as yup from 'yup';
import handleError from '../util/handleError';

const InputAddon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 40px;
  font-size: 18px;
  display: flex;
  visibility: ${({ focused }) => (focused ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  color: #2e2e2e;
  transition: all 0.1s ease-in-out;
`;

const DonationSchema = yup.object().shape({
  subscriptionType: yup.string(),
  name: yup.string().required('Naam mag niet leeg zijn'),
  email: yup
    .string()
    .email('E-mail is niet geldig')
    .required('Email mag niet leeg zijn'),
  amount: yup
    .number()
    .moreThan(4.99, 'Het minimumbedrag is 5 euro')
    .required('Bedrag mag niet leeg zijn'),
  message: yup.string(),
  accountNumber: yup.string(),
});

const DonationForm = ({ setDonation, setLoading, setServerError }) => {
  const { register, errors, handleSubmit, setError } = useForm({
    mode: 'onBlur',
    validationSchema: DonationSchema,
  });

  const [subscriptionType, setSubscriptionType] = useState('one-time-donation');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const donationId = localStorage.getItem('donationId');

    const getPayment = async donationId => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.GATSBY_SERVER_URL}/payment/${donationId}`,
        );
        setDonation({ ...response.data });
      } catch (error) {
        handleError(error, setServerError, setError);
      } finally {
        setLoading(false);
      }
    };

    if (donationId) {
      getPayment(donationId);
      setTimeout(() => {
        localStorage.removeItem('donationId');
      }, 1000);
    }
  }, [setLoading, setDonation, setServerError, setError]);

  const getSubscription = async (customerId, subscriptionId) => {
    try {
      const url = `${process.env.GATSBY_SERVER_URL}/subscription/${customerId}/${subscriptionId}`;
      const response = await axios.get(url);
      setDonation({ ...response.data });
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let url;
      if (subscriptionType === 'one-time-donation') {
        url = `${process.env.GATSBY_SERVER_URL}/payment`;
      } else {
        url = `${process.env.GATSBY_SERVER_URL}/subscription`;
      }
      const payload = { ...formData };
      payload.message = payload.message || 'No message';
      payload.amount = ((payload.amount * 100) / 100).toFixed(2);
      const response = await axios.post(url, payload);

      if (response.data._links.checkout) {
        localStorage.setItem('donationId', response.data.id);
        return (window.location.href = response.data._links.checkout.href);
      }

      const { customerId, id: subscriptionId } = response.data;
      getSubscription(customerId, subscriptionId);
    } catch (error) {
      handleError(error, setServerError, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      width="full"
      flexDirection={['column', null, 'row']}
      alignItems="center"
      justifyContent="center"
      my={4}
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
      p={4}
    >
      <Box width={[1, 1, 1 / 2]} px={[0, 0, 2, 4]} pb={4} mr={3}>
        <Flex
          as="form"
          flexDirection="column"
          justifyContent="center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup>
            <FormControl
              ref={register}
              onChange={e => setSubscriptionType(e.target.value)}
              as="select"
              name="subscriptionType"
              defaultValue={subscriptionType}
            >
              <option value="one-time-donation">Eenmalige Donatie</option>
              <option value="monthly">Maandelijks</option>
              <option value="each-quarter">Elk Kwartaal</option>
              <option value="annually">Jaarlijks</option>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl
              ref={register}
              notValid={errors.name}
              name="name"
              placeholder="Naam"
              error={errors.name}
            />
            {errors.name && <span>{errors.name.message}</span>}
          </FormGroup>
          <FormGroup>
            <FormControl
              ref={register}
              notValid={errors.email}
              type="email"
              name="email"
              placeholder="Email"
            />
            {errors.email && <span>{errors.email.message}</span>}
          </FormGroup>
          {subscriptionType !== 'one-time-donation' && (
            <FormGroup>
              <FormControl
                ref={register({
                  required: subscriptionType !== 'one-time-donation',
                })}
                notValid={errors.accountNumber}
                name="accountNumber"
                placeholder="IBAN"
              />
              {errors.accountNumber && (
                <span>{errors.accountNumber.message}</span>
              )}
            </FormGroup>
          )}
          <FormGroup position="relative">
            <FormControl
              ref={register}
              notValid={errors.amount}
              onFocus={e => {
                !e.target.value && (e.target.value = 5.0);
                setFocused(true);
              }}
              onBlur={e => !e.target.value && setFocused(false)}
              type="number"
              name="amount"
              placeholder="Bedrag"
              step="0.10"
              pl={focused && '30px'}
              max={
                subscriptionType === 'one-time-donation' ? '99999999' : '1000'
              }
            />
            <InputAddon className="addon" focused={focused}>
              €
            </InputAddon>
            {errors.amount && <span>{errors.amount.message}</span>}
          </FormGroup>
          <FormGroup>
            <FormControl
              ref={register}
              as="textarea"
              name="message"
              placeholder="Bericht"
            />
            {errors.message && <span>{errors.message.message}</span>}
          </FormGroup>
          <FormGroup>
            <Button type="submit" color="background">
              Verstuur
            </Button>
          </FormGroup>
          <Typography textAlign="center">
            Wil je een periodieke overboeking annuleren? Klik{' '}
            <Link to="/donation/cancel">hier.</Link>
          </Typography>
        </Flex>
      </Box>
      <Box width={[1, 1, 1 / 2]}>
        <img width="100%" src={donationDraw} alt="donation" />
      </Box>
    </Flex>
  );
};

export default DonationForm;
