const handleError = (error, setServerError, setError) => {
  console.error(
    'SERVER ERROR',
    `Error code: ${error.response ? error.response.status : 500}`,
    `Error message: ${
      error.message ||
      error.response.data.message ||
      error.response.data.detail ||
      error.response.data.errors.map(error => error.msg) ||
      error.response.data
    }`,
  );
  setServerError(error.response || error);
  if (setError) {
    setError(error);
  }
};

export default handleError;
