import React from 'react';

import Box from '../../components/styled/base/Box';
import Typography from '../../components/styled/base/Typography';
import Button from '../../components/styled/Button';

const ErrorReport = ({ serverError, setServerError }) => {
  return (
    <Box>
      <Typography>
        Er is een fout opgetreden. Probeer het later opnieuw.
      </Typography>
      <Typography>Mogelijke redenen zijn:</Typography>
      <ul>
        {serverError.data ? (
          serverError.data.errors ? (
            <>
              {serverError.data.errors.map((error, index) => {
                return <li key={index}>{error.msg}</li>;
              })}
            </>
          ) : serverError.data.message ? (
            <li>
              <b>{serverError.data.message}</b>
            </li>
          ) : (
            serverError.data.detail && (
              <li>
                <b>{serverError.data.detail}</b>
              </li>
            )
          )
        ) : (
          <li>
            <b>{serverError.message}</b>
          </li>
        )}
      </ul>
      <Button
        color="background"
        mb="4"
        width="20rem"
        height="40px"
        fontWeight="semibold"
        fontSize="3"
        onClick={() => setServerError(false)}
      >
        Probeer opnieuw
      </Button>
    </Box>
  );
};

export default ErrorReport;
